/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Delete } from '@mui/icons-material';
import DOMPurify from 'dompurify';
import {
  DragDropContext, Droppable, Draggable, DropResult,
} from 'react-beautiful-dnd';

import Button from 'components/library/Button';
import profilePicture from 'assets/base_profil_gray.png';
import { ProjectTeam, ProjectTeamContent } from 'types/project';
import { ProjectContext } from 'context/project';
import isIE from 'utils/isIE';

import Editor from '../ProjectContentSection/ProjectContentSectionEdition';
import ProjectContentLayout from '../ProjectContentLayout';
import styles from './ProjectContentTeam.module.css';
import ProjectContentTeamEdit from './ProjectContentTeamEdit';

interface ProjectContentTeamProps {
  data: ProjectTeamContent,
  onChange: (section: string, value: ProjectTeamContent) => void,
}

export default function ProjectContentTeam({ data, onChange }: ProjectContentTeamProps) {
  const [{ isOpen, isDelete, selected }, setPopUp] = useState({
    isOpen: false,
    isDelete: false,
    selected: '',
  });
  const {
    isAdmin, mode, lang,
  } = useContext(ProjectContext);
  const { t } = useTranslation();

  const getLanguage = () => lang;
  const getMember = () => {
    if (selected) {
      return (data[getLanguage()] || [])
        .find((member) => member.name === selected) || undefined;
    }
    return undefined;
  };

  const handleDisplayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTeam = { ...data, isDisplay: e.target.checked };
    onChange('team', newTeam);
  };

  const handleOpenPopUp = () => setPopUp({ isOpen: true, isDelete: false, selected: '' });
  const handleEdit = (e: React.MouseEvent<HTMLButtonElement>) => setPopUp({
    isOpen: true,
    isDelete: false,
    selected: (e.target as HTMLButtonElement).dataset.id || '',
  });
  const handleDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    setPopUp({
      isOpen: true,
      isDelete: true,
      selected: (e.target as HTMLButtonElement).dataset.id || '',
    });
  };
  const handleClose = () => setPopUp({ isOpen: false, isDelete: false, selected: '' });

  // TODO: remplacer les any
  const handleEditorChange = (event: any, editor: any) => {
    if (editor.getData() !== '') {
      onChange('team', ({
        ...data,
        raw: {
          ...data.raw,
          [lang]: editor.getData(),
        },
      }));
    } else onChange('team', ({ ...data, raw: { ...data.raw, [lang]: ((data[lang] || '').length > 7) ? data[lang] : '<p></p>' } }));
  };

  const onDelete = () => {
    const newMemberList = (data[getLanguage()] || []).filter((member) => member.name !== selected);
    const newValue = {
      ...data,
      [getLanguage()]: newMemberList,
    };
    onChange('team', newValue);
    handleClose();
  };

  const onUpdate = (newMember: ProjectTeam) => {
    let newValue;
    if (selected) {
      newValue = {
        ...data,
        [getLanguage()]: (data[getLanguage()] || []).map((member) => {
          if (member.name === selected) return newMember;
          return member;
        }),
      };
    } else {
      newValue = {
        ...data,
        [getLanguage()]: [...(data[getLanguage()] || []), newMember],
      };
    }
    onChange('team', newValue);
    handleClose();
  };

  const handleOnDragEnd = (result: DropResult) => {
    if (result.destination?.index !== undefined) {
      const items = data[getLanguage()];
      if (items) {
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        const newValue = {
          ...data,
          [getLanguage()]: items,
        };
        onChange('team', newValue);
      }
    }
  };

  return (
    <ProjectContentLayout
      section="team"
      isDisplay={data.isDisplay}
      onDisplayChange={handleDisplayChange}
    >
      <>
        {
          (isAdmin && mode === 'edition' && !(isIE() && isIE() <= 9))
            ? (
              <Suspense fallback={<p>Loading...</p>}>
                <Editor
                  className={`${styles.editor} ${styles['editor-container']}`}
                  data={(data?.raw && data?.raw[getLanguage()]) || ''}
                  onChange={handleEditorChange}
                />
              </Suspense>
            )
            : (
              (data?.raw && data.raw[getLanguage()])
              && (
                <div
                  className={styles.editor}
                  dangerouslySetInnerHTML={{ __html: data.raw[getLanguage()] || '' }}
                />
              )
            )
        }
        {
          (isOpen)
          && (
            <ProjectContentTeamEdit
              isDelete={isDelete}
              selected={getMember()}
              onDelete={onDelete}
              onUpdate={onUpdate}
              close={handleClose}
            />
          )
        }
        {
          (isAdmin && mode === 'edition')
          && (
            <Button
              onClick={handleOpenPopUp}
            >
              Ajouter un nouveau membre
            </Button>
          )
        }
        {
          (mode === 'preview')
          && (
            <div className={styles.list}>
              {
                (data && data[getLanguage()])
                && (data[getLanguage()] || []).map((member) => (
                  <div
                    key={member.name}
                    className={styles.member}
                  >
                    {
                      (member.img)
                        ? (
                          <img
                            src={member.img}
                            alt={member.name}
                            className={styles.picture}
                          />
                        )
                        : (
                          <img
                            src={profilePicture}
                            alt={member.name}
                            className={styles.picture}
                          />
                        )
                    }
                    <p className={styles.name}>
                      {member.name}
                    </p>
                    <hr className={styles.highlight} />
                    <p className={styles.title}>
                      {member.title}
                    </p>
                    <div
                      className={`${styles.editor} ${styles.preview}`}
                      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(member.description || '') }}
                    />
                  </div>
                ))
              }
            </div>
          )
        }
        {
          (mode === 'edition')
          && (
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="teams">
                {
                  (provided) => (
                    <div
                      className={styles.list}
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {
                        (data && data[getLanguage()])
                        && (data[getLanguage()] || []).map((member, index) => (
                          <Draggable draggableId={member.name} index={index} key={member.name}>
                            {
                              (providedItem) => (
                                <div
                                  className={styles.member}
                                  ref={providedItem.innerRef}
                                  {...providedItem.draggableProps}
                                  {...providedItem.dragHandleProps}
                                >
                                  {
                                    (member.img)
                                      ? (
                                        <img
                                          src={member.img}
                                          alt={member.name}
                                          className={styles.picture}
                                        />
                                      )
                                      : (
                                        <img
                                          src={profilePicture}
                                          alt={member.name}
                                          className={styles.picture}
                                        />
                                      )
                                  }
                                  <p className={styles.name}>
                                    {member.name}
                                  </p>
                                  <hr className={styles.highlight} />
                                  <p className={styles.title}>
                                    {member.title}
                                  </p>
                                  <div
                                    className={`${styles.editor} ${styles.preview}`}
                                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(member.description || '') }}
                                  />
                                  {
                                    (isAdmin)
                                    && (
                                      <>
                                        <Button
                                          id={member.name}
                                          onClick={handleEdit}
                                        >
                                          {t('edition.team.edit')}
                                        </Button>
                                        <Button
                                          variant="ghost"
                                          type="custom"
                                          className={styles.delete}
                                          id={member.name}
                                          onClick={handleDelete}
                                        >
                                          <Delete className={styles.trash} />
                                          {t('edition.team.trash')}
                                        </Button>
                                      </>
                                    )
                                  }
                                </div>
                              )
                            }
                          </Draggable>
                        ))
                      }
                      {
                        provided.placeholder
                      }
                    </div>
                  )
                }
              </Droppable>
            </DragDropContext>
          )
        }
      </>
    </ProjectContentLayout>
  );
}
