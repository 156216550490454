import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Edit } from '@mui/icons-material';

import {
  Project, ProjectUser,
} from 'types/project';
import Button from 'components/library/Button';
import InvestButton from 'components/InvestButton';
import trackUser from 'utils/trackuser';
import { ProjectContext } from 'context/project';

import ProjectHeaderSocial from './ProjectHeaderSocial';
import ProjectHeaderSummary from './ProjectHeaderSummary';
import ProjectHeaderSimulation from './ProjectHeaderSimulation';
import styles from './ProjectHeader.module.css';
import ProjectHeaderLang from './ProjectHeaderLang';
import ProjectHeaderEdition from './ProjectHeaderEdition';
import WhatsApp from '../ProjectSimulation/ProjectSimulationContent/WhatsAppButtonGreenLarge.svg';
import { trackWhatsAppEvent } from '../../../../utils/logs';

interface ProjectHeaderProps {
  lang?: string[],
  project: Project,
  updateProject: (value: Project) => void,
  id: string,
  calendarLink: string,
  user?: ProjectUser,
}

export default function ProjectHeader({
  project, updateProject, id, user, calendarLink, lang,
}: ProjectHeaderProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const { isAdmin, mode } = useContext(ProjectContext);

  const handleEdition = () => setIsOpen((curr) => !curr);

  const handleCalendarClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    trackUser(window.projectName, 123, 'Prendre un rendez-vous');
    window.open(calendarLink, '_blank');
  };

  return (
    <header className={styles.header}>
      {
        (isAdmin && mode === 'edition')
        && (
          <Button
            className={styles.edit}
            onClick={handleEdition}
          >
            <Edit className={styles.icon} />
            <span>
              {t('header.edit')}
            </span>
          </Button>
        )
      }
      {
        (isOpen)
        && (
          <ProjectHeaderEdition
            project={project}
            updateProject={updateProject}
            close={handleEdition}
          />
        )
      }
      <div className={styles.navigation}>
        {
          (project.content.header.logo)
            ? (
              <img
                src={project.content.header.logo}
                alt={project.content.header.name}
                className={styles.logo}
              />
            )
            : (<p className={styles.logoName} translate="no">{project.content.header.name}</p>)
        }
        {
          ((isAdmin && mode === 'edition') || (lang && lang?.length > 1))
          && (
            <ProjectHeaderLang />
          )
        }
      </div>
      <div className={styles.row}>
        <ProjectHeaderSummary
          header={project.content.header}
          minimum={project.simulation.minimum}
        />
        <div className={styles.column}>
          <ProjectHeaderSocial />
          <div className={styles.group}>
            <img
              src={project.content.header.picture}
              alt={project.content.header.name}
              className={styles.cover}
            />
            <ProjectHeaderSimulation
              name={project.content.header.name}
              calendarLink={calendarLink}
              id={id}
              user={user}
              minimum={project.simulation.minimum || 500}
              maximum={Number.parseFloat(project.content.header.funds)}
              valuation={Number.parseFloat(project.content.header.valuation.replace('€', ''))}
              sharePrice={project.simulation.share}
              rate={project.simulation.taxExemption.includes('IR') ? project.simulation.rate : undefined}
            />
          </div>
        </div>
        <div className={styles['button-group']}>
          <InvestButton
            project={project.content.header.name}
            history={project.simulation.minimum || 500}
            id={id}
          />
          <div
            className={styles.whatsapp}
          >
            <a
              aria-label="Chat on WhatsApp"
              href="https://wa.me/+33755540409"
              target="_blank"
              rel="noreferrer"
              onClick={trackWhatsAppEvent}
            >
              <img alt="Chat on WhatsApp" src={WhatsApp} />
            </a>
          </div>
          <p className={styles.help}>
            {t('navigation.help')}
            <a
              className={styles.link}
              href={calendarLink}
              onClick={handleCalendarClick}
            >
              {t('header.rdv')}
            </a>
          </p>
        </div>
      </div>
    </header>
  );
}
