import axios from 'axios';
import axiosRetry from 'axios-retry';

import domains from '../../global';
import { getCookie } from '../../utils/trackuser';

const axiosMockClient = axios.create({
  baseURL: domains.url,
  timeout: 120000,
});

export const axiosAuthClient = axios.create({
  baseURL: `${domains.auth}/api`,
  timeout: 60000,
});

const cookie = getCookie('csrftoken');
if (cookie) {
  axiosMockClient.defaults.headers.put['X-CSRFToken'] = cookie;
  axiosMockClient.defaults.headers.post['X-CSRFToken'] = cookie;
}

axiosRetry(axiosMockClient, {
  retries: 3,
  shouldResetTimeout: true,
  retryCondition: (error) => {
    const isTimeout = error.code === 'ECONNABORTED';
    const isServerError = error.response && error.response.status >= 500;
    return isTimeout || isServerError;
  },
  retryDelay: (retryCount) => retryCount * 1000,
});

export default axiosMockClient;
