/* eslint no-underscore-dangle: 0 */
import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ToggleSwitch from 'components/ToggleSwitch';
import { ProjectContext } from 'context/project';

import styles from './ProjectContentLayout.module.css';

interface ProjectContentLayoutProps {
  section: string,
  isDisplay: boolean,
  onDisplayChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  children: React.ReactNode,
}

export default function ProjectContentLayout({
  section, isDisplay, onDisplayChange, children,
}: ProjectContentLayoutProps) {
  const [showTitle, setShowTitle] = useState(true);
  const { isAdmin, mode } = useContext(ProjectContext);
  const { t } = useTranslation();

  useEffect(() => {
    // Depth first search of the children to find <h2> elements
    let cancel = false;

    const hasTitleOnTop = (node: React.ReactNode) => {
      if (cancel) return;
      if (node && typeof node === 'object') {
        // @ts-ignore
        if (node.props) {
          // @ts-ignore
          // @ts-ignore
          if (node.props.dangerouslySetInnerHTML && node.props.dangerouslySetInnerHTML.__html && node.props.dangerouslySetInnerHTML.__html?.substring && node.props.dangerouslySetInnerHTML.__html?.substring(0, 3)?.includes('<h2')) {
            setShowTitle(false);
            cancel = true;
          } else {
            setShowTitle(true);
            // @ts-ignore
            const { children: nodeChildren } = node.props;
            if (nodeChildren && nodeChildren.forEach !== undefined) {
              nodeChildren.forEach((child: React.ReactNode) => hasTitleOnTop(child));
            } else {
              hasTitleOnTop(nodeChildren);
            }
          }
        } else {
          setShowTitle(true);
        }
      }
    };
    if (isDisplay) hasTitleOnTop(children);
  }, [children]);

  return (
    <div className={styles.section} id={`section-${section}`}>
      {
        (isDisplay || (isAdmin && mode === 'edition'))
        && (
          <div className={styles.row}>
            {
              (showTitle || (isAdmin && mode === 'edition'))
              && (
                <div>
                  <h3 className={styles.title}>
                    { t(`presentation.${section}`) }
                  </h3>
                  <hr className={styles.separator} />
                </div>
              )
            }
            {
              (isAdmin && mode === 'edition')
              && (
                <ToggleSwitch
                  name={section}
                  checked={isDisplay}
                  onChange={onDisplayChange}
                />
              )
            }
          </div>
        )
      }
      { (isDisplay || (isAdmin && mode === 'edition')) && children }
    </div>
  );
}
